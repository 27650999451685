<template>
  <v-layout column>
    <v-layout justify-end align-center row wrap>
      <div>
        <v-checkbox
          v-model="withAgreement"
          :label="$t('marketingReport.withAgreement')"
          color="primary"
        ></v-checkbox>
      </div>
      <div>
        <v-btn color="primary" @click="generateReport">{{
          $t("global.generateReport")
        }}</v-btn>
      </div>
    </v-layout>
    <ContactAgreementTable
      :ref="tableRefName"
      :marketing-agree="withAgreement"
      @processing="dataProcessing"
    ></ContactAgreementTable>
    <v-layout>
      <PrintSave
        :disabled="!dataLoaded"
        :file-url="downloadReportUrl"
        @printClick="printReport"
      ></PrintSave>
    </v-layout>
  </v-layout>
</template>
<script>
import Printer from "../common/HtmlPrinter";
import ContactAgreementTable from "../components/Reports/ContactAgreementTable";
import PrintSave from "../components/PrintSave";
import FileService from "../services/FileService.js"
export default {
  name: "ReportContactAgreement",
  components: { ContactAgreementTable, PrintSave },
  data() {
    return {
      tableRefName: "table",
      withAgreement: false,
      dataLoaded: false,
      lastRequest: {}
    };
  },
  computed: {
    printTitle() {
      return this.lastRequest.withAgreement
        ? this.$t("marketingReport.withAgreement")
        : this.$t("marketingReport.withoutAgreement");
    },
    downloadReportUrl() {
      return FileService.ReportContactAgreementUrl(this.lastRequest.withAgreement);
    }
  },
  methods: {
    dataProcessing(loading) {
      this.dataLoaded = loading;
    },
    generateReport() {
      this.lastRequest = { withAgreement: this.withAgreement };
      this.$refs[this.tableRefName].getData(this.withAgreement);
    },
    printReport() {
      Printer.PrintElement(this.$refs[this.tableRefName].$el, this.printTitle, {
        landscape: true
      });
    }
  }
};
</script>
