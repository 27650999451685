var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.options.totalItems,"items-per-page":5},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.insertTime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFilter")(value))+" ")]}},{key:"item.isQuarantine",fn:function(ref){
var item = ref.item;
return [(item.isQuarantine)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"text-primary font-weight-black"},on),[_vm._v("K")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("rodo.isQuarantine")))])]):_vm._e()]}},{key:"item.expirationDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateTimeFilter")(value))+" ")]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","indeterminate":""},slot:"progress"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }